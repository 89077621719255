.google {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 8px 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgb(255 255 255 / 13%), 0 6px 6px rgb(255 255 255 / 31%);
  transition: 0.5s;

  &:hover {
    box-shadow: 0 3px 15px #3fadff, 0 1px 3px #3fadff;
    transition: 0.5s;
  }

  .title {
    margin-left: 10px;
    color: #7a7a7a;
    font-weight: 400;
    font-size: 16px;
  }
}

.wrapper {
  width: fit-content;
}

.a {
  width: fit-content;
}
