@import '../../../styles/colors.module';

.hr {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #fff;
  line-height: 0.1em;
  color: #fff;
  font-size: 14px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.hr span {
  background: #262525;
  padding: 0 10px;
}

.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  //background: $gradient1;
  background: linear-gradient(95.83deg, #B4D4FF 4.09%, rgb(187 182 182) 64.5%),#fff;
  //background: linear-gradient(#03e9f470, #f48803) padding-box, linear-gradient(45deg, #f48803, #03e9f4) border-box;

}

.welcome {
  position: absolute;
  top: -80px;
  left: -40px;
  font-size: 30px;
  color: #ffffff;
}

@media only screen and (max-width: 750px) {
  .welcome {
    left: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .welcome {
    display: none;
  }
}

.authForm {
  width: 600px;
  margin: auto;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
  background-color: $black900;
  position: relative;
  color: white;

  .header {
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;

    .home {
      transition: 0.5s;
      &:hover {
        transition: 0.5s;
        color: #939393;
      }
    }

    h2 {
      padding: 0px;
      margin: 0px;
      margin-bottom: 25px;
      color: white;
      font-weight: 400;
    }
  }

  .oauthWrapper {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .authForm {
    width: 100%;
    margin: auto;
    border-radius: 0px;
    padding: 40px;
    box-shadow: none;
    background-color: $black900;
    position: absolute;
    height: 100%;
  }
}
