.userBox {
  position: relative;

  input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: white;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid white;
    outline: none;
    background: transparent;


  }
  input:focus ~ label,
  input:valid ~ label,
  .notEmpty {
    top: -30px;
    left: 0;
    color: #03e9f4;
    font-size: 14px;
  }

  input:visited ~ label{
    top: -20px;
    left: 0;
    color: tomato;
    font-size: 14px;
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 16px;
    color: white;
    pointer-events: none;
    transition: 0.5s;
  }
}
